define("discourse/plugins/discourse-perspective-api/discourse/templates/composer/perspective-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href {{action "closeMessage"}} class="close">{{d-icon "close"}}</a>
  
  <h3>{{i18n "perspective.perspective_messages"}}</h3>
  
  <div class="messages">
    <p>{{i18n "perspective.perspective_warning"}}</p>
  </div>
  */
  {
    "id": "OWraz5Zd",
    "block": "[[[11,3],[24,6,\"\"],[24,0,\"close\"],[4,[38,0],[[30,0],\"closeMessage\"],null],[12],[1,[28,[35,1],[\"close\"],null]],[13],[1,\"\\n\\n\"],[10,\"h3\"],[12],[1,[28,[35,2],[\"perspective.perspective_messages\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"messages\"],[12],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,2],[\"perspective.perspective_warning\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"action\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-perspective-api/discourse/templates/composer/perspective-message.hbs",
    "isStrictMode": false
  });
});